/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

body {
    background: #f5f8fc;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.section .section-title {
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
    padding-bottom: 7px;
    font-weight: 600;
}

.section .section-subtitle {
    font-size: calc(13px + (13 - 13) * ((100vw - 320px) / (1200 - 320)));
    font-weight: 500;
    margin-bottom: 0px;
    opacity: .9;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.w-100 {
    width: 100% !important;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}